@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

@layer base {
    body {
        @apply font-[Raleway];
    }
    li {
        @apply px-4;
        @apply cursor-default
    }
}
.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
  width: 430px;
}

.content-div:hover {
  background-image: linear-gradient(
      to right,
      rgba(112, 157, 255, 0.8),
      hsla(242, 74%, 61%, 0.8)   
  )!important;
}

html,body{
  overflow-x: hidden;
}
.sectionClass {
  padding: 80px 0px 50px 0px;
  position: relative;
  display: block;
}

.row {
  width: 980px;
  height: 100%;
  max-width: 100%;
  margin: 0 auto;
}

.row:before,
.row:after {
  content: "";
  display: table;
}

.fullWidth {
  width: 100%;
  display: table;
  float: none;
  padding: 0;
  min-height: 1px;
  height: 100%;
  position: relative;
}
/********************************/
/*  SECTION WORK EXPERIENCE
********************************/

#work-experience .sectiontitle .headerLine {
  width: 280px;
}

#work-experience .headerline {
  width: 280px;
}

.cbp_tmtimeline {
  margin: 60px 30px 0 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.cbp_tmtimeline:before {
  content: '';
  position: absolute;
  top: 3%;
  bottom: 0;
  width: 10px;
  background: #324454;
  left: 13%;
}

.cbp_tmtimeline li:last-child:before {
  content: initial;
}

.cbp_tmtimeline > li .cbp_tmtime {
  display: block;
  width: 25%;
  padding-right: 100px;
  position: absolute;
}

.cbp_tmtimeline > li .cbp_tmtime span {
  display: block;
  text-align: right;
}

.cbp_tmtimeline > li .cbp_tmtime span:first-child {
  font-size: 0.9em;
  color: #bdd0db;
}

.cbp_tmtimeline > li .cbp_tmtime span:last-child {
  font-size: 2.9em;
  color: #3594cb;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmtime span:last-child {
  color: #6cbfee;
}

.cbp_tmtimeline > li .cbp_tmlabel {
  margin: 0 0 15px 25%;
  background: #c5d6d9;
  color: #FFF;
  padding: 30px;
  font-size: 1.2em;
  font-weight: 300;
  line-height: 1.4;
  /* font-family: 'Open Sans'; */
  position: relative;
  border-radius: 5px;
  min-height: 150px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel {
  background: #c5d6d9;
}

.cbp_tmtimeline > li .cbp_tmlabel h3 {
  margin-top: 0px;
  color: black;
  font-size: 20px;
  margin-bottom: 5px;
  padding: 0 0 10px 0;
  /* font-family: 'Open Sans', sans-serif; */
  font-weight: bold;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 {
  opacity: 0.7;
  color: black;
  letter-spacing: 0px;
  /* font-family: 'Source Sans Pro', sans-serif; */
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
  padding: 0;
  padding-bottom: 10px;
  margin: 0;
  text-align: left;
}

.cbp_tmtimeline > li .cbp_tmlabel h4 i {
  margin-right: 5px;
  vertical-align: middle;
}

.cbp_tmtimeline > li .cbp_tmlabel:after {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: rgba(50, 68, 84, 1);
  border-width: 10px;
  top: 70px;
}

.cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
  border-right-color: #c5d6d9;
}

.cbp_tmtimeline > li .nasuni{
  width: 150px;
  height: 150px;
  top: 3%;
  background-image: url(../src/assets/exp/nasuni.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}

.cbp_tmtimeline > li .deloitte{
  width: 150px;
  height: 150px;
  top: 3%;
  background-image: url(../src/assets/exp/deloitte.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}
.cbp_tmtimeline > li .o9{
  width: 150px;
  height: 150px;
  top: 3%;
  background-image: url(../src/assets/exp/o9.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}
.cbp_tmtimeline > li .hp{
  width: 150px;
  height: 150px;
  top: 3%;
  background-image: url(../src/assets/exp/hp.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}
.cbp_tmtimeline > li .cycops{
  width: 150px;
  height: 150px;
  top: 3%;
  background-image: url(../src/assets/exp/cycops.png);
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  left: 8%;
  margin: 0 0 0 -25px;
}

.cbp_tmtimeline li {
  margin-bottom: 70px;
  position: relative;
}

.sectionClassProject {
  position: relative;
  display: block;
  /* background: #f7f7f7; */
  
  margin: 0 auto;
  padding: 80px 1.875em 3.125em;
}

.projectParagraph {
  font-size: 18px;
  margin: 0.5em 0 0;
  color: black;
  /* font-family: 'Source Sans Pro', serif; */
}

.projectParagraphLink {
  font-size: 15px !important;
  font-weight: 500 !important;
  margin-top: 50px !important;
  margin-bottom: 0px;
  text-align: right;
}

.projectParagraphLink a {
  color: white;
  text-decoration: underline;
}

.cbp_tmicon img {
  width: 100%;
}

.label {
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  color: #FFFFFF;
  display: inline;
  font-size: 12px;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px 15px;
}

.date {
  color: black;
  display: block;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  top: 30px;
  right: 20px;
}

.date i {
  margin-right: 8px;
  vertical-align: top;
  font-size: 18px;
  line-height: 20px;
}

@media (max-width: 1024px) {
  .cbp_tmtimeline:before {
    display: none;
  }
  .cbp_tmtimeline > li .cbp_tmtime {
    width: 100%;
    position: relative;
    padding: 0 0 20px 0;
  }
  .cbp_tmtimeline > li .cbp_tmtime span {
    text-align: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel {
    margin: 30px 0 70px 0;
    padding: 50px 30px 30px 30px;
    font-weight: 400;
    font-size: 95%;
    float: left;
  }
  .cbp_tmtimeline > li .cbp_tmlabel:after {
    right: auto;
    border-right-color: transparent;
    border-bottom-color: rgb(50, 68, 84);
    top: -20px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmlabel:after {
    border-right-color: transparent;
    border-bottom-color: rgb(43, 58, 72);
    left: 65px;
  }
  .cbp_tmtimeline > li:nth-child(even) .cbp_tmlabel:after {
    right: 65px;
  }
  .cbp_tmtimeline > li:nth-child(odd) .cbp_tmicon {
    position: relative;
    float: left;
    left: auto;
    margin: 0px 5px 0 0px;
  }
  .cbp_tmtimeline > li:nth-child(even) .cbp_tmicon {
    position: relative;
    float: right;
    left: auto;
    margin: 0px 5px 0 0px;
  }
  .cbp_tmtimeline > li .cbp_tmtime span:last-child {
    font-size: 1.5em;
  }
}

@media (max-width: 32em) {
  .cbp-ntaccordion {
    font-size: 70%;
  }
   .resize{
    width: 330px;
  }
}
.upper{
  margin-top: -32px;
}
.point{
  cursor: pointer;
}
